@font-face {
  font-family: "DoctorSoosBold";
  src: url("./fonts/Doctor-Soos-Bold.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "VisbyRound";
  src: url("./fonts/VisbyRoundCF-Regular.otf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "MuseoSansBold";
  src: url("./fonts/Museo-Sans-900.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "AllisonScript";
  src: url("./fonts/Allison_Script.otf");
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  font-weight: bold;
}

/* Ant Design  */
.ant-form-explain {
  font-family: "VisbyRound";
  font-size: 12px !important;
  margin-bottom: 5px !important;
  margin-left: 5px;
}

.ant-modal-footer {
  display: none;
}

.ant-input {
  height: 50px !important;
  font-weight: 600;
  font-size: 16px !important;
  font-family: "VisbyRound";
  border-radius: 30px !important;
}

.create-form-select .ant-select-selection__placeholder,
.ant-select-search__field__placeholder {
  text-align: center !important;
  font-family: "VisbyRound";
}

.ant-select-selection__placeholder,
.ant-select-search__field__placeholder {
  text-align: center !important;
  font-family: "VisbyRound";
}

.ant-select-selection--single {
  height: 50px !important;
  font-weight: 600;
  font-size: 16px !important;
  font-family: "VisbyRound";
  border-radius: 30px !important;
}

.ant-select-selection__rendered {
  display: flex !important;
  align-items: center;
  justify-content: center;
  height: 50px;
}

.ant-input:hover {
  border-color: #ff6f61 !important;
}

.ant-input:focus {
  box-shadow: 0 0 0 1px #ff6f61 !important;
  border-color: #ff6f61 !important;
}

.ant-select-selection--single:hover {
  border-color: #ff6f61 !important;
}

.ant-select-selection--single:focus {
  box-shadow: 0 0 0 1px #ff6f61 !important;
  border-color: #ff6f61 !important;
}

.ant-spin-dot-item {
  background-color: #ff6f61 !important;
}

.ant-spin {
  color: #ff6f61 !important;
}

.ant-divider-horizontal {
  min-width: 0% !important;
  margin: 10px 0px !important;
}

.ant-menu-horizontal {
  border-bottom: none !important;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #45bca026 !important;
}

.ant-menu-item-selected {
  color: #fe5f60 !important;
}

.ant-menu-item:hover {
  border-bottom-color: #ffffff !important;
  color: #45bca0 !important;
}

.ant-menu-item,
.ant-menu-submenu-title {
  font-family: "DoctorSoosBold";
  font-size: 16px;
}

.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-submenu:hover,
.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-submenu-active,
.ant-menu-horizontal > .ant-menu-item-open,
.ant-menu-horizontal > .ant-menu-submenu-open,
.ant-menu-horizontal > .ant-menu-item-selected,
.ant-menu-horizontal > .ant-menu-submenu-selected {
  color: #45bca0 !important;
  border-bottom: 2px solid #45bca0 !important;
}

.ant-menu-submenu-selected {
  border-bottom-color: #ffffff !important;
  color: #fe5f60 !important;
}

.ant-menu-submenu-title:hover {
  border-bottom-color: #ffffff !important;
  color: #45bca0 !important;
}

.ant-menu-submenu:hover {
  border-bottom-color: #ffffff !important;
  color: #45bca0 !important;
}

.ant-menu-horizontal {
  padding: 10px;
}

.ant-menu-submenu::selection {
  border-bottom-color: #ffffff !important;
  color: #45bca0 !important;
}

html {
  --antd-wave-shadow-color: #fe5f60 !important;
}
